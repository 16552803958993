export const bannerTypes = [
  {
    name: 'Character Event',
    id: 'character-event',
  },
  {
    name: 'Weapon Event',
    id: 'weapon-event',
  },
  {
    name: 'Standard',
    id: 'standard',
  },
  {
    name: "Beginners' Wish",
    id: 'beginners',
  },
  {
    name: 'Chronicled Wish',
    id: 'chronicled',
  },
];
